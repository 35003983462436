export function newTurma() {
    return {
        modalidade_id: 0,
        turma_tipo: 1,
        quantidade_aula_semana: 1,
        quantidade_aula_por_dia: 2,
        cronograma_horario: [],
        previsao_inicio: null,
        previsao_termino: null,
        observacao: '',
        idioma: '',
        curso_nome: '',
    };
}

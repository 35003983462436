import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { turmaStore, userActionStore } from '@/utils/store-accessor';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
let TurmaCancelarComponent = class TurmaCancelarComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.turma = {};
        this.turmaAlunos = [];
        this.dialogRequestPermission = false;
    }
    get disableButton() {
        for (const turmaAluno of this.turmaAlunos) {
            if (this.verificaStatusVigenteNaTurma(turmaAluno)) {
                return true;
            }
        }
        return false;
    }
    get userActionsCancelarTurmaRequestPermission() {
        return userActionStore.cancelarTurmaRequestPermission;
    }
    get userActionsCancelarTurmaObject() {
        return userActionStore.cancelarTurmaObject;
    }
    verificaStatusVigenteNaTurma(turmaAluno) {
        if ([1, 4, 7, 8].includes(turmaAluno.status_do_aluno_na_turma_id)) {
            return true;
        }
        return false;
    }
    async show() {
        this.turma = await turmaStore.getTurma(this.propTurmaId);
        this.turmaAlunos = await turmaStore.getTurmaAlunos({
            turma_id: this.propTurmaId,
        });
        this.dialog = true;
    }
    async cancelarTurma() {
        if (this.userActionsCancelarTurmaRequestPermission &&
            !this.dialogRequestPermission) {
            this.dialogRequestPermission = true;
            return;
        }
        await turmaStore.cancelarTurma(this.propTurmaId);
        this.$router.back();
    }
    async descancelarTurma() {
        if (this.userActionsCancelarTurmaRequestPermission &&
            !this.dialogRequestPermission) {
            this.dialogRequestPermission = true;
            return;
        }
        await turmaStore.descancelarTurma(this.propTurmaId);
        this.$router.back();
    }
};
__decorate([
    Prop({ default: null, type: Number })
], TurmaCancelarComponent.prototype, "propTurmaId", void 0);
TurmaCancelarComponent = __decorate([
    Component({
        components: { RequestPermissionComponent },
    })
], TurmaCancelarComponent);
export default TurmaCancelarComponent;

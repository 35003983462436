import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
let GestaoAlunoReunioesComponent = class GestaoAlunoReunioesComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Tipo', value: 'reuniao_tipo_id' },
            { text: 'Data', value: 'data_hora_agendamento' },
            { text: 'Status', value: 'status_id' },
            { text: 'Observação da ocorrência', value: 'observacao_ocorrencia' },
            { text: 'Descrição da tarefa', value: 'descricao_tarefa' },
            { text: 'Observação da tarefa', value: 'observacao_tarefa' },
        ];
    }
    get reunioes() {
        return this.propReunioes;
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], GestaoAlunoReunioesComponent.prototype, "propReunioes", void 0);
GestaoAlunoReunioesComponent = __decorate([
    Component({
        components: { TextExpandableComponent },
    })
], GestaoAlunoReunioesComponent);
export default GestaoAlunoReunioesComponent;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { userActionStore, turmaAlunoAtividadeStore, mainStore } from '@/store';
import UploadDownloadFileButton from '@/components/UploadDownloadFileButton.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
let NotaGestaoButton = class NotaGestaoButton extends Vue {
    constructor() {
        super(...arguments);
        this.fichaDeNotasMenus = {};
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    get fichaDeNotasConceitoEditOptions() {
        if (this.currentUser.company.escola_id === 1) {
            return [
                'Ótimo',
                'Bom',
                'Regular',
                'Insatisfatório',
                'N/D',
            ];
        }
        return [
            'Excelente',
            'Bom',
            'Aprimorar',
            'Observar',
            'N/D',
        ];
    }
    get permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente() {
        return userActionStore.permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente;
    }
    files(e) {
        return e.target.files;
    }
    validateNota(value) {
        return (value >= 0 && value <= 10) || 'Nota inválida';
    }
    getEdicaoDesabilitada() {
        const isAlunoVigente = this.propStatusAlunoNaTurmaId == 1;
        // se esta vigente pode alterar
        if (isAlunoVigente) {
            return false;
        }
        else {
            if (this.permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente) {
                // aluno nao vigente mas pode alterar de nao vigente
                return false;
            }
            else {
                return true;
            }
        }
    }
    fichaDeNotasActions(actions) {
        if (!actions) {
            return { text: 'N/D', color: '' };
        }
        const arr = actions.split('-');
        if (arr[0].length > 0) {
            const value = parseFloat(arr[0]);
            if (Number.isNaN(value)) {
                if (this.currentUser.company.escola_id === 1) {
                    switch (arr[0]) {
                        case 'Ótimo':
                            return { text: arr[0], color: 'green' };
                        case 'Bom':
                            return { text: arr[0], color: 'green' };
                        case 'Regular':
                            return { text: arr[0], color: 'orange' };
                        case 'Insatisfatório':
                            return { text: arr[0], color: '#EB5406' };
                    }
                }
                else {
                    switch (arr[0]) {
                        case 'Excelente':
                            return { text: arr[0], color: 'green' };
                        case 'Bom':
                            return { text: arr[0], color: 'green' };
                        case 'Aprimorar':
                            return { text: arr[0], color: 'orange' };
                        case 'Observar':
                            return { text: arr[0], color: '#EB5406' };
                    }
                }
            }
            if (value >= 8) {
                return { text: arr[0], color: 'green' };
            }
            else if (value >= 7) {
                return { text: arr[0], color: 'orange' };
            }
            else {
                return { text: arr[0], color: '#EB5406' };
            }
        }
        return { text: 'N/D', color: '' };
    }
    async fichaDeNotasEditOptionsClick(item, nota, aluno, tipo) {
        if (nota && (nota === '' || nota.substr(0, 1) === '-')) {
            this.fichaDeNotasMenus[`${item.turma_atividade_id}_${aluno}_${tipo}`] =
                false;
            return;
        }
        if (parseFloat(nota) < 0 || parseFloat(nota) > 10) {
            this.fichaDeNotasMenus[`${item.turma_atividade_id}_${aluno}_${tipo}`] =
                false;
            return;
        }
        const oldValue = item[aluno];
        const id = item[aluno].split('-')[1];
        const bucketArquivoAtividadeId = item[aluno].split('-')[2];
        const bucketArquivoCorrecaoId = item[aluno].split('-')[3];
        const obj = {
            id,
        };
        switch (tipo) {
            case 'homework':
                // @ts-ignore
                obj.atividade_nota = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
            case 'fala':
                // @ts-ignore
                obj.atividade_nota_fala = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
            case 'leitura':
                // @ts-ignore
                obj.atividade_nota_leitura = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
            case 'audicao':
                // @ts-ignore
                obj.atividade_nota_audicao = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
            case 'escrita':
                // @ts-ignore
                obj.atividade_nota_escrita = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
            case 'preparacao':
                // @ts-ignore
                obj.atividade_nota_preparacao = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
            case 'participacao':
                // @ts-ignore
                obj.atividade_nota_participacao = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id + '-' + bucketArquivoAtividadeId + '-' + bucketArquivoCorrecaoId;
                break;
        }
        if (!(await turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj))) {
            item[aluno] = oldValue;
        }
        this.fichaDeNotasMenus[`${item.turma_atividade_id}_${aluno}_${tipo}`] =
            false;
    }
    showLogTurmaAlunoAtividade(ref, id) {
        const form = ref;
        form.show(id);
    }
    formatName(name) {
        const names = name.toLowerCase().split(' ');
        const formattedNames = names.map((x) => {
            return x.charAt(0).toUpperCase() + x.slice(1);
        });
        return formattedNames.join(' ');
    }
    trigger() {
        this.$refs.fileInput.click();
    }
};
__decorate([
    Prop()
], NotaGestaoButton.prototype, "value", void 0);
__decorate([
    Prop(String)
], NotaGestaoButton.prototype, "propTipoAtividade", void 0);
__decorate([
    Prop(Object)
], NotaGestaoButton.prototype, "propItem", void 0);
__decorate([
    Prop(String)
], NotaGestaoButton.prototype, "propAluno", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], NotaGestaoButton.prototype, "propIfInicial", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], NotaGestaoButton.prototype, "propIsMobile", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], NotaGestaoButton.prototype, "propAulaOnline", void 0);
__decorate([
    Prop({ type: String, required: true })
], NotaGestaoButton.prototype, "propAlunoNome", void 0);
__decorate([
    Prop({ type: Number, required: true })
], NotaGestaoButton.prototype, "propStatusAlunoNaTurmaId", void 0);
__decorate([
    Emit()
], NotaGestaoButton.prototype, "files", null);
NotaGestaoButton = __decorate([
    Component({
        components: {
            UploadDownloadFileButton,
            LogShowComponent,
        },
    })
], NotaGestaoButton);
export default NotaGestaoButton;
